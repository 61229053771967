
import { ENGLISH, FRENCH } from './language.types';


export const switchToEnglish = () => {
    return {

        type: ENGLISH,

    };

};

export const switchToFrench = () => {

    return {

        type: FRENCH,

    };

};
